import { PhoneDto } from '@/types/webapi';

export const IProfileServiceId = Symbol.for('IProfileService');

export interface IProfileService {
    /**
     * Sends phone confirmation code to provided phone number.
     * @param phone Phone object to send verification code to
     */
    sendPhoneConfirmation(phone: PhoneDto): Promise<void>;

    /**
     * Verifies provided phone verification code.
     * @param data Data to verify
     */
    verifyPhoneConfirmation(phone: PhoneDto, code: string): Promise<boolean>;
}
