<template>
    <v-navigation-drawer
        v-model="_modelValue"
        :location="isMobile ? 'bottom' : 'left'"
        :width="isMobile ? 0 : 480"
        color="white"
        elevation="4"
        border="0"
        :rounded="isMobile && !fullHeight ? 't-lg' : '0'"
        permanent
        class="app-drawer"
        :class="cssClasses"
        :style="mainStyles"
    >
        <div class="app-drawer__inner">
            <div class="app-drawer__content">
                <slot />
            </div>
            <div class="app-drawer__toggle d-none d-md-flex align-md-center">
                <v-btn
                    color="white"
                    width="30"
                    min-width="30"
                    height="54"
                    rounded="s-0 e-lg"
                    class="pa-0 ma-0"
                    @click="_modelValue = !_modelValue"
                >
                    <v-icon size="36" :icon="_modelValue ? 'mdi-menu-left' : 'mdi-menu-right'" />
                </v-btn>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { useDisplayHelpers } from '@/features/common/composables';
import { computed } from 'vue';
import { useLayout } from 'vuetify';

interface Props {
    modelValue: boolean;
    fullHeight?: boolean;
}

const props = withDefaults(defineProps<Props>(), { fullHeight: true });
const _modelValue = useVModel(props, 'modelValue');
const { isMobile } = useDisplayHelpers();
const cssClasses = computed(() => ({ 'app-drawer--full-height': props.fullHeight }));
const { mainStyles } = useLayout();
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.app-drawer {
    .v-navigation-drawer__content {
        overflow-y: auto;

        /* Style the scrollbar to match container */
        &::-webkit-scrollbar {
            width: 8px; /* width of the scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* track color */
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2); /* thumb color */
            border-radius: 4px;
            border: 2px solid transparent; /* creates padding around scroll thumb */
            background-clip: padding-box;
        }

        /* For Firefox */
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    }

    &__toggle {
        pointer-events: none;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            position: absolute;
            top: 0;
            right: -30px;
            height: 100%;
        }

        .v-btn {
            pointer-events: all;
            clip-path: inset(0 -5px 0 0);
        }
    }

    &--full-height {
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            height: calc(100% - var(--v-layout-top)) !important;
        }
    }
}
</style>
