import { PhoneDto, SendPhoneConfirmationDto, VerifyPhoneConfirmationDto, VerifyPhoneConfirmationResultDto } from '@/types/webapi';
import { IProfileService } from './IProfileService';
import { inject, injectable } from 'inversify';
import { IAxiosId } from '@/plugins/axios';
import { AxiosInstance } from 'axios';

@injectable()
export class ProfileService implements IProfileService {
    @inject(IAxiosId)
    protected axios: AxiosInstance;

    sendPhoneConfirmation(phone: PhoneDto): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.axios
                .post('/api/v1.0/profile/phone-confirmation', { phone } satisfies SendPhoneConfirmationDto)
                .then(() => resolve())
                .catch(reject);
        });
    }

    verifyPhoneConfirmation(phone: PhoneDto, code: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.axios
                .post<VerifyPhoneConfirmationResultDto>('/api/v1.0/profile/phone-confirmation/verification', {
                    phone,
                    code
                } satisfies VerifyPhoneConfirmationDto)
                .then(response => resolve(response.data.verified || false))
                .catch(reject);
        });
    }
}
