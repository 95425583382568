import { useContainer } from '@/plugins/inversify';
import { IConfigService, IConfigServiceId } from '@/services/IConfigService';
import { AppConfigDto } from '@/types/webapi';
import { MenuStructure, useMenuProvider } from '@geta/kolumbus-frontend/composables';
import { isNull, isObject, isUndefined, merge } from 'lodash-es';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { DevModeConfiguration } from '../models';

export enum SearchMode {
    None = 0,
    Trip = 1,
    Departures = 2
}

export const useMainStore = defineStore('mainStore', () => {
    const container = useContainer();
    const configService = container.get<IConfigService>(IConfigServiceId);

    const ready = ref(false);
    const isLoading = ref(false);
    const language = ref(document.documentElement.lang || 'no');
    const isDebugMode = computed(() => !!config.value.isDebugModeEnabled);
    const searchMode = ref<SearchMode>(SearchMode.Trip);
    const defaultConfig = ref<AppConfigDto>();
    const config = ref<AppConfigDto & { supportedLanguages: string[] }>({
        kolumbusWebsiteBaseUrl: '',
        supportedLanguages: ['no', 'en'],
        googleMapsConfig: {
            apiKey: '',
            center: {
                latitude: 58.97,
                longitude: 5.7331
            }
        },
        silentLoginEnabled: true,
        defaultCulture: 'no',
        isDebugModeEnabled: false,
        devModeEnabled: false
    });
    const menuItems = ref<MenuStructure>({ baseUrl: '/' });
    const lastKnownError = ref<Error | null>(null);
    const showAppDrawer = ref(true);
    const isDevMode = ref(false);
    const devModeConfiguration = ref<DevModeConfiguration | undefined>();

    const menu = computed(() => menuItems.value);
    const currentTime = ref<Date>(new Date());

    async function init() {
        try {
            isLoading.value = true;

            // fetch app config
            defaultConfig.value = await configService.getConfig();

            // set up app state
            const supportedLanguages = merge(['no', 'en'], menuItems.value.supportedLanguages?.map(l => l.code) || []);
            config.value = merge(config.value, defaultConfig.value, { supportedLanguages });
            language.value = config.value.defaultCulture;

            // set up menus
            const menuProvider = useMenuProvider(config.value.kolumbusWebsiteBaseUrl);
            menuItems.value = await menuProvider.getMenuItems();
        } catch (e) {
            registerError(e);
        } finally {
            isLoading.value = false;
        }
    }

    function toggleSearch() {
        searchMode.value = searchMode.value === SearchMode.None ? SearchMode.Trip : SearchMode.None;
    }

    function registerError(error: any) {
        lastKnownError.value = isErrorWithMessage(error) ? error : new Error(String(error));
    }

    setInterval(() => (currentTime.value = new Date()), 1000);

    return {
        ready,
        isLoading,
        language,
        isDebugMode,
        searchMode,
        defaultConfig,
        config,
        menuItems,
        lastKnownError,
        showAppDrawer,
        isDevMode,
        devModeConfiguration,
        currentTime,
        menu,
        init,
        toggleSearch,
        registerError
    };
});

function isErrorWithMessage(error: unknown): error is Error {
    return !isUndefined(error) && !isNull(error) && isObject(error) && 'message' in error && typeof error.message === 'string';
}
